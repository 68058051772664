body {
  position: relative;
  height: 100%;
  background: #fff;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0,0,0,0.7);
  font-weight: normal;
  font-family: 'Dmsans', sans-serif;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  display: block;
  max-width: 100%;
}
input[type="text"],
input[type="email"],
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
input[type="number"]-webkit-inner-spin-button,
input[type="number"]-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
table {
  border-collapse: collapse;
  border-width: 0px;
  padding: 0px;
  margin: 0px;
}
html {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
input,
textarea {
  color: rgba(0,0,0,0.7);
  font-family: 'Dmsans', sans-serif;
  border-radius: 0;
}
input[type="button"],
input[type="submit"],
button {
  cursor: pointer;
  font-family: 'Dmsans', sans-serif;
  font-size: 16px;
  line-height: 24px;
}
td {
  margin: 0px;
  padding: 0px;
}
ul {
  list-style: none;
}
form {
  padding: 0px;
  margin: 0px;
}
a {
  color: $link-color;
  transition: all 0.3s ease-out;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
a,
span,
div,
button {
  outline: none !important;
}
input[type=submit],
input[type=button],
button {
  -webkit-appearance: none;
  outline: none;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*before,
*after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfixafter,
.wrapperafter,
.rowafter {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.loaded .main-wrapper {
  opacity: 0;
}
.icon-load {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transform: translate3d(0, -100%, 0);
  transition: all 0.3s ease-out;
}
.loaded .icon-load {
  transform: translate3d(0, 0%, 0);
}
.sk-folding-cube {
  width: 40px;
  height: 40px;
  transform: rotateZ(45deg);
}
.sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  background: #fff;
  transform: scale(0.96);
}
.sk-folding-cube .sk-cubebefore {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $accent;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  transform: scale(0.97) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  transform: scale(0.97) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  transform: scale(0.97) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2before {
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3before {
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4before {
  animation-delay: 0.9s;
}
inputplaceholder,
textareaplaceholder {
  transition: opacity 0.2s ease-out;
}
inputfocusplaceholder,
textareafocusplaceholder {
  opacity: 0;
}
@-moz-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@-o-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@font-face {
  font-family: "Dmsans";
  src: url("../fonts/dmsans/DMSans-Regular.eot");
  src: local("DMSans-Regular"), url("../fonts/dmsans/DMSans-Regular.eot?#iefix") format('embedded-opentype'), url("../fonts/dmsans/DMSans-Regular.woff2") format('woff2'), url("../fonts/dmsans/DMSans-Regular.woff") format('woff'), url("../fonts/dmsans/DMSans-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Dmsans";
  src: url("../fonts/dmsans/DMSans-Medium.eot");
  src: local("DMSans-Medium"), url("../fonts/dmsans/DMSans-Medium.eot?#iefix") format('embedded-opentype'), url("../fonts/dmsans/DMSans-Medium.woff2") format('woff2'), url("../fonts/dmsans/DMSans-Medium.woff") format('woff'), url("../fonts/dmsans/DMSans-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Dmsans";
  src: url("../fonts/dmsans/DMSans-Bold.eot");
  src: local("DMSans-Bold"), url("../fonts/dmsans/DMSans-Bold.eot?#iefix") format('embedded-opentype'), url("../fonts/dmsans/DMSans-Bold.woff2") format('woff2'), url("../fonts/dmsans/DMSans-Bold.woff") format('woff'), url("../fonts/dmsans/DMSans-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@-moz-keyframes typing {
  from {
    width: 0;
    border-color: rgba(255,255,255,0.4);
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes typing {
  from {
    width: 0;
    border-color: rgba(255,255,255,0.4);
  }
  to {
    width: 100%;
  }
}
@-o-keyframes typing {
  from {
    width: 0;
    border-color: rgba(255,255,255,0.4);
  }
  to {
    width: 100%;
  }
}
@keyframes typing {
  from {
    width: 0;
    border-color: rgba(255,255,255,0.4);
  }
  to {
    width: 100%;
  }
}
@-moz-keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: rgba(255,255,255,0.4);
  }
}
@-webkit-keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: rgba(255,255,255,0.4);
  }
}
@-o-keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: rgba(255,255,255,0.4);
  }
}
@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: rgba(255,255,255,0.4);
  }
}
.footer {
  padding-bottom: 60px;
}
.footer__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer__logo {
  max-width: 134px;
  width: 100%;
}
.footer__text {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}
.footer__text.mobile {
  max-width: 278px;
  margin: auto;
  text-align: center;
  margin-top: 30px;
}
.footer__bottom {
  margin-top: 35px;
}
.footer__text,
.footer .copyright {
  color: rgba(0,0,0,0.5);
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
}
.header {
  position: relative;
  padding: 30px 0;
  z-index: 11;
}
.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__logo {
  max-width: 134px;
  width: 100%;
}
.menu ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu a {
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  font-weight: 500;
  letter-spacing: -0.44px;
  padding: 10px 20px;
  opacity: 0.7;
  transition: all 0.3s ease-out;
}
.menu a:hover {
  color: #ffc737;
}
.burg {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,0.1);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px 12px;
}
.burg__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.burg__line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #323232;
  border-radius: 2px;
  transition: all 0.3s ease-out;
}
.burg__line:first-child {
  top: 0;
}
.burg__line:nth-child(2) {
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.burg__line:last-child {
  bottom: 0;
}
.burg.active .burg__line:first-child {
  transform: rotate(45deg);
  top: unset;
}
.burg.active .burg__line:nth-child(2) {
  height: 0;
}
.burg.active .burg__line:last-child {
  transform: rotate(-45deg);
  bottom: unset;
}
.side {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 50vh;
  background-color: #fff;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  transform: translateY(-120%);
  transition: all 0.3s ease-out;
  z-index: 10;
}
.side__inner {
  padding-top: 150px;
  padding-bottom: 100px;
  height: 100%;
}
.side.active {
  transform: translateY(0);
}
.side .menu ul {
  justify-content: center;
  flex-direction: column;
}
.side .menu li {
  margin: 20px 0;
}
.fancybox__backdrop {
  background: rgba(0,0,0,0.5);
}
.is-close-btn {
  display: none;
}
.modal {
  max-width: 640px;
  width: 100%;
  margin: auto;
  border-radius: 20px;
}
.modal__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal__icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}
.modal__title {
  text-align: center;
}
.modal__title h2 {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -1.5px;
}
.modal__text {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
}
.modal__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.wow {
  visibility: hidden;
}
.body.disabled {
  overflow: hidden;
}
.main {
  position: relative;
}
.main:after {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0,0,0,0.5);
  transition: all 0.3s ease-out;
  z-index: 3;
}
.main.disabled:after {
  opacity: 1;
  visibility: visible;
}
.main-wrapper {
  padding: 0 0 0 0;
  min-width: 320px;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.wrapper {
  min-width: 320px;
  max-width: 1320px;
  padding: 0 20px 0 20px;
  margin: 0 auto;
  position: relative;
}
.wrapper--narrow {
  max-width: 990px;
}
p {
  font-size: 16px;
  line-height: 24px;
  font-family: 'Dmsans', sans-serif;
  color: rgba(0,0,0,0.7);
  font-weight: 400;
}
h1 {
  font-size: 72px;
  line-height: 76px;
  font-family: 'Dmsans', sans-serif;
  color: #000;
  font-weight: 600;
}
h2 {
  font-size: 62px;
  line-height: 66px;
  font-family: 'Dmsans', sans-serif;
  color: #000;
  font-weight: 600;
}
.owl-theme .owl-dots .owl-dot span {
  margin: 0 7px;
  width: 5px;
  height: 5px;
  background: transparent;
  border: 1px solid rgba(0,0,0,0.4);
}
.owl-theme .owl-dots .owl-dot.active span {
  border: none;
  background: #171719;
}
input {
  outline: none;
  margin: 0;
  padding: 0;
  border: 0;
  background: unset;
  background-color: unset;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 10px;
  appearance: none;
  transition: all 0.3s ease-out;
}
input[type="submit"] {
  border-color: #000;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  letter-spcing: -0.5px;
}
input[type="submit"].yellow {
  background-color: #ffc737;
  color: #000;
  border-color: #ffc737;
}
input[type="submit"].yellow:hover {
  background-color: #000;
  color: #fff;
  border-color: #000;
}
input[type="submit"]:hover {
  background-color: #ffc737;
  border-color: #ffc737;
}
.socials__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.socials__item {
  display: block;
}
.socials__item:not(:last-child) {
  margin-right: 20px;
}
.main {
  min-width: 320px;
  width: 100%;
  flex-grow: 1;
  position: relative;
}
.btn {
  display: inline-block;
  color: #000;
  letter-spacing: -0.5px;
  padding: 12px 20px;
  border-radius: 10px;
  background-color: #ffc737;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.btn:hover {
  background-color: #000;
  color: #fff;
}
.btn--only_icon {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn--only_icon svg {
  width: 100%;
  heiht: 100%;
}
.btn--only_icon path {
  transition: all 0.3s ease-out;
}
.btn--only_icon:hover path {
  stroke: #fff;
}
.btn--black {
  background-color: #000;
  color: #fff;
}
.btn--black:hover {
  background-color: #ffc737;
}
.title--center {
  text-align: center;
}
.title--with_imgs {
  display: flex;
  align-items: flex-end;
}
.title .highlight {
  text-align: left;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffc737;
  border-radius: 15px;
  padding: 2px 25px 2px 20px;
  color: #fff;
  vertical-align: text-bottom;
  height: 85px;
}
.title__imgs {
  margin-left: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.section {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  z-index: 2;
}
.section.no_top_pad {
  padding-top: 0;
}
.section--hero {
  z-index: 3;
  margin-top: 20px;
  padding-top: 0;
  padding-bottom: 0;
}
.section--yellow {
  background-color: #ffc737;
}
.section--video {
  padding-top: 368px;
  margin-top: -246px;
}
.section--inner {
  padding-top: 30px;
}
.hero {
  padding: 12px 12px 80px 25px;
  border-radius: 20px;
  border: 1px solid rgba(0,0,0,0.09);
  background-color: #fff;
}
.hero.no_top {
  padding: 105px 12px 115px;
}
.hero-top {
  width: 100%;
  margin-bottom: 80px;
}
.hero-top__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hero-top__tab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 412px;
  width: 100%;
  color: #666;
  font-size: 13px;
  line-height: 16px;
  border-radius: 5px;
  padding: 4px 10px 8px;
  text-align: center;
  background-color: rgba(0,0,0,0.05);
}
.hero-top__tab img {
  margin-right: 5px;
}
.hero-top__tab span {
  margin-bottom: -3px;
}
.hero-top__arrows {
  position: absolute;
  top: 2px;
  left: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-top__arrows div {
  width: 36px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-top__arrows img {
  margin: auto;
}
.hero-top__icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hero-top__icons div:not(:last-child) {
  margin-right: 8px;
}
.hero-top__form {
  margin-top: 55px;
}
.hero__title h1 {
  letter-spacing: -4px;
}
.hero__subtitle {
  font-size: 16px;
  line-height: 16px;
  color: #000;
  margin-top: 40px;
  opacity: 0.6;
}
.hero__subtitle--center {
  text-align: center;
}
.hero__subtitle--black {
  opacity: 1;
}
.hero__form {
  margin-top: 55px;
}
.hero__brands {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero__brands div {
  margin: 0 30px;
}
.typewrite {
  display: inline-block;
  overflow: hidden;
  padding-right: 10px;
  border-right: 6px solid rgba(255,255,255,0.4);
  white-space: nowrap;
  line-height: 1;
  transition: all 0.3s ease-out;
  animation: blink-caret 0.5s step-end infinite;
}
.form {
  max-width: 440px;
  margin: auto;
}
.input--grow {
  flex-grow: 1;
  margin-right: 10px;
}
.input--grow input {
  width: 100%;
}
.inputs__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.video__main {
  position: relative;
  padding-bottom: 56.25%;
  border-radius: 20px;
  cursor: pointer;
}
.video__main:hover .video__title h2 {
  color: #ffc737;
}
.video__main:hover .video__icon path {
  fill: #ffc737;
}
.video__layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  transition: all 0.3s ease-out;
}
.video__layer.hidden {
  opacity: 0;
}
.video__info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
}
.video__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/main/video-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  z-index: 1;
}
.video__title {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}
.video__title h2 {
  font-size: 82px;
  line-height: 76px;
  font-weight: bold;
  letter-spacing: -5px;
  color: #fff;
  transition: all 0.3s ease-out;
}
.video__icon {
  transition: all 0.3s ease-out;
}
.video__icon path {
  transition: all 0.3s ease-out;
}
.video__content {
  margin-top: 120px;
}
.video__blocks {
  margin-top: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.video__blocks div {
  border-radius: 20px;
  padding: 40px 0 64px;
  width: calc(50% - 40px);
}
.video__blocks div.white {
  background-color: #fff;
  padding: 40px 40px 64px;
}
.video__blocks div p {
  font-size: 16px;
  line-height: 24px;
}
.funcs__main {
  margin-top: 100px;
  margin-bottom: -120px;
}
.funcs__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 120px;
}
.funcs__row.reverse {
  flex-direction: row-reverse;
}
.funcs__block {
  width: calc(50% - 40px);
}
.funcs__title h2 {
  font-size: 42px;
  line-height: 46px;
  letter-spacing: -2px;
}
.funcs__text,
.funcs__btn {
  margin-top: 30px;
}
.features__blocks {
  margin-top: 60px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px 25px;
}
.features__blocks.mobile .features__block {
  min-height: 376px;
  width: 100%;
  padding: 30px 30px 55px;
}
.features__block {
  background-color: #fff;
  width: calc(33% - 15px);
  padding: 30px;
  border-radius: 20px;
  min-height: 376px;
}
.features__block.black {
  background-color: #000;
}
.features__block.black .features__subtitle {
  color: #fff;
}
.features__block.black .features__text p {
  color: #ffc737;
}
.features__subtitle {
  color: #000;
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: -1.5px;
  margin-top: 20px;
}
.features__icon {
  width: 60px;
  heigth: 60px;
}
.features__text {
  margin-top: 20px;
}
.features__text p {
  font-size: 16px;
  line-height: 24px;
}
.who__blocks {
  margin-top: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
}
.who__blocks.mobile .who__block {
  width: 100%;
  min-height: 500px;
}
.who__block {
  background-color: #ffc737;
  border-radius: 20px;
  width: calc(33% - 15px);
}
.who__main {
  padding: 30px;
}
.who__img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  height: 255px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.who__subtitle {
  font-size: 30px;
  line-height: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: -1.5px;
  color: #000;
}
.who__text,
.who__hidden {
  font-size: 16px;
  line-height: 24px;
  color: #000;
  transition: max-height 0.5s ease-in;
}
.who__hidden {
  overflow: hidden;
  max-height: 0;
}
.subtitle {
  margin-top: 20px;
}
.content {
  margin-top: 25px;
}
.content h2 {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -1.5px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.content p {
  padding-top: 5px;
  padding-bottom: 15px;
}
@media (max-width: 890px) {
  h1 {
    font-size: 42px;
    line-height: 35px;
  }
  .highlight {
    line-height: 54px;
  }
  .title .highlight {
    height: 60px;
  }
  .hero__title h1 {
    letter-spacing: -2.5px;
  }
}
@media (min-width: 769px) {
  .burg,
  .btn--header_mobile {
    display: none;
  }
  .features__blocks.mobile {
    display: none;
  }
  .who__blocks.mobile {
    display: none;
  }
  .footer__text.mobile {
    display: none;
  }
}
@media (max-width: 768px) {
  h2 {
    font-size: 42px;
    line-height: 46px;
    letter-spacing: -2px;
  }
  .header__menu {
    display: none;
  }
  .footer {
    padding-bottom: 60px;
  }
  .footer__wrapper {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .footer__text.desktop {
    display: none;
  }
  .footer__btns {
    margin-top: 30px;
  }
  .footer__bottom {
    margin-top: 40px;
  }
  .footer__logo {
    margin-bottom: 30px;
  }
  .btn--header_desktop {
    display: none;
  }
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .section--hero {
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 10px;
  }
  .section--video {
    padding-top: 310px;
  }
  .section--funcs {
    padding-bottom: 20px;
  }
  .section--features .wrapper,
  .section--who .wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .hero {
    padding: 10px 20px 50px;
  }
  .hero-top {
    margin-bottom: 48px;
  }
  .hero-top__tab {
    display: none;
  }
  .hero__subtitle {
    margin-top: 25px;
    font-size: 14px;
    line-height: 20px;
  }
  .hero__form {
    margin-top: 30px;
  }
  .hero__brands {
    margin-top: 40px;
    flex-wrap: wrap;
    gap: 20px 0;
  }
  .hero__brands div {
    width: calc(50% - 20px);
    margin: 0;
  }
  .hero__brands div img {
    margin: auto;
  }
  .hero.no_top {
    padding: 40px 30px 50px;
  }
  .video__blocks {
    margin-top: 30px;
    flex-wrap: wrap;
  }
  .video__blocks div {
    width: 100%;
    padding: 0;
    margin-bottom: 25px;
  }
  .video__blocks div.white {
    padding: 30px 30px 35px;
  }
  .video__title {
    margin-bottom: 0;
  }
  .video__title h2 {
    font-size: 42px;
    line-height: 42px;
    letter-spacing: -2.5px;
  }
  .video__icon {
    width: 75px;
    height: 75px;
  }
  .video__icon svg {
    width: 100%;
    height: 100%;
  }
  .video__content {
    margin-top: 60px;
  }
  .funcs__row {
    flex-wrap: wrap;
    padding-bottom: 40px;
  }
  .funcs__main {
    margin-bottom: 0;
  }
  .funcs__block {
    width: 100%;
  }
  .funcs__title .title {
    text-align: center;
  }
  .funcs__title h2 {
    font-size: 32px;
    line-height: 36px;
  }
  .funcs__btn {
    margin-top: 20px;
  }
  .funcs__btn .btn {
    display: block;
    text-align: center;
  }
  .funcs__text {
    margin-top: 20px;
  }
  .funcs__main {
    margin-top: 40px;
  }
  .funcs__anim {
    padding-bottom: 20px;
  }
  .features__blocks {
    margin-top: 30px;
  }
  .features__blocks.desktop {
    display: none;
  }
  .who__blocks {
    margin-top: 30px;
  }
  .who__blocks.desktop {
    display: none;
  }
}
@media (max-width: 580px) {
  .inputs__row {
    flex-direction: column;
  }
  .inputs__row .input:not(:first-child) {
    margin-top: 10px;
  }
  input::placeholder {
    text-align: center;
  }
  .input {
    margin-right: 0;
    width: 100%;
  }
  .input input {
    width: 100%;
  }
  .title--with_imgs {
    justify-content: space-between;
  }
  .title__imgs {
    flex-direction: column;
  }
  .socials__item:not(:last-child) {
    margin-right: 50px;
  }
  .footer__text {
    font-size: 12px;
    line-height: 18px;
  }
}
@media (max-width: 480px) {
  .highlight {
    margin: 10px 20px;
  }
  .hero__form {
    max-width: 275px;
    margin: 30px auto 0;
  }
  .modal {
    padding: 32px 20px;
  }
}
