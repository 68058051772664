//main styles

.wow
	visibility hidden

.body
	&.disabled
		overflow hidden

.main
	position relative
	&:after
		content ''
		top 0
		left 0
		position absolute
		width 100%
		height 100%
		opacity 0
		visibility hidden
		background-color rgba(0,0,0,.5)
		transition $trans
		z-index 3
	&.disabled
		&:after
			opacity 1
			visibility visible


.main-wrapper
	padding 0 0 0 0
	min-width $min-width
	width 100%
	position relative
	overflow hidden
	min-height 100%
	display flex
	flex-direction column
	justify-content flex-start

.wrapper
	min-width $min-width
	max-width $max-width
	padding 0 20px 0 20px
	margin 0 auto
	position relative
	&--narrow
		max-width 990px

/* titles */
p
	font($base-size, $base-line, $text-color, $base-font, 400)

h1
	font($h1-size, $h1-line, $title-color, $base-font, 600)

h2
	font($h2-size, $h2-line, $title-color, $base-font, 600)

/* styles */

.owl-theme
	.owl-dots 
		.owl-dot 
			span
				margin 0 7px
				width 5px
				height 5px
				background transparent
				border 1px solid rgba(0,0,0,.4)
			&.active
				span
					border none
					background #171719

input
	outline none
	margin 0
	padding 0
	border 0
	background unset
	background-color unset
	padding 12px 20px
	font-size 14px
	line-height 16px
	font-weight 500
	border 1px solid rgba(0,0,0,.1)
	border-radius 10px
	appearance none
	transition $trans
	&[type="submit"]
		border-color $black
		background-color $black
		color $white
		font-size 14px
		line-height 16px
		letter-spcing -.5px
		&.yellow
			background-color $yellow
			color $black
			border-color $yellow
			&:hover
				background-color $black
				color $white
				border-color $black
		&:hover
			background-color $yellow
			border-color $yellow

.socials
	&__wrapper
		display flex
		align-items center
		justify-content flex-start
	&__item
		display block
		&:not(:last-child)
			margin-right 20px

.main
	min-width $min-width
	width 100%
	flex-grow 1
	position relative

.btn
	display inline-block
	color $black
	letter-spacing -.5px
	padding 12px 20px
	border-radius 10px
	background-color $yellow
	font-weight 500
	font-size 14px
	line-height 16px
	cursor pointer
	transition $trans
	&:hover
		background-color $black
		color $white
	&--only_icon
		padding 8px
		display flex
		align-items center
		justify-content center
		svg
			width 100%
			heiht 100%
		path
			transition $trans
		&:hover
			path
				stroke $white
	&--black
		background-color $black
		color $white
		&:hover
			background-color $yellow

.title
	&--center
		text-align center
	&--with_imgs
		display flex
		align-items flex-end
	.highlight
		text-align left
		display inline-flex
		align-items center
		justify-content flex-start
		background-color $yellow
		border-radius 15px
		padding 2px 25px 2px 20px
		color $white
		vertical-align text-bottom
		height 85px
	&__imgs
		margin-left 37px
		display flex
		align-items center
		justify-content center
		gap 5px
		

.section
	position relative
	padding-top 120px
	padding-bottom 120px
	z-index 2
	&.no_top_pad
		padding-top 0
	&--hero
		z-index 3
		margin-top 20px
		padding-top 0
		padding-bottom 0
	&--yellow
		background-color $yellow
	&--video
		padding-top 368px
		margin-top -246px
	&--inner
		padding-top 30px

.hero
	padding 12px 12px 80px 25px
	border-radius 20px
	border 1px solid rgba(0, 0, 0, 0.09)
	background-color $white
	&.no_top
		padding 105px 12px 115px
	&-top
		width 100%
		margin-bottom 80px
		&__wrapper
			display flex
			align-items center
			justify-content space-between
		&__tab
			position relative
			display flex
			align-items center
			justify-content center
			max-width 412px
			width 100%
			color $light_grey
			font-size 13px
			line-height 16px
			border-radius 5px
			padding 4px 10px 8px
			text-align center
			background-color rgba(0, 0, 0, 0.05)
			img
				margin-right 5px
			span
				margin-bottom -3px
		&__arrows
			position absolute
			top 2px
			left -100px
			display flex
			align-items center
			justify-content center
			div
				width 36px
				height 24px
				display flex
				align-items center
				justify-content center
			img
				margin auto
		&__icons
			display flex
			align-items center
			justify-content flex-start
			div
				&:not(:last-child)
					margin-right 8px
		&__form
			margin-top 55px
	&__title
		h1
			letter-spacing -4px
	&__subtitle
		font-size 16px
		line-height 16px
		color $black
		margin-top 40px
		opacity .6
		&--center
			text-align center
		&--black
			opacity 1
	&__form
		margin-top 55px
	&__brands
		margin-top 80px
		display flex
		align-items center
		justify-content center
		div
			margin 0 30px

.typewrite
	display inline-block
	overflow hidden
	padding-right 10px
	border-right 6px solid rgba(255,255,255,.4)
	white-space nowrap
	line-height 1
	// opacity 0
	// width 0
	//visibility hidden
	//animation-play-state paused
	transition $trans
	animation blink-caret .5s step-end infinite
	// &.active
	// 	display inline-flex
	// 	opacity 1
	// 	visibility visible
	// 	animation typing 1.5s steps(30, end) forwards, blink-caret .5s step-end infinite
		

.form
	max-width 440px
	margin auto

.input
	&--grow
		flex-grow 1
		margin-right 10px
		input
			width 100%

.inputs
	&__row
		display flex
		align-items center
		justify-content space-between

.video
	iframe
		position absolute
		top 0
		left 0
		width 100%
		height 100%
		border-radius 20px
	&__main
		position relative
		padding-bottom 56.25%
		border-radius 20px
		cursor pointer
		&:hover
			.video
				&__title
					h2
						color $yellow
				&__icon
					path
						fill $yellow
	&__layer
		position absolute
		top 0
		left 0
		width 100%
		height 100%
		border-radius 20px
		transition $trans
		&.hidden
			opacity 0
	&__info
		position absolute
		top 0
		left 0
		right 0
		bottom 0
		width 100%
		height 100%
		display flex
		align-items center
		justify-content center
		flex-direction column
		z-index 2
	&__img
		position absolute
		top 0
		left 0
		right 0
		bottom 0
		width 100%
		height 100%
		background-image url('../img/main/video-bg.png')
		background-position center
		background-repeat no-repeat
		background-size cover
		border-radius 20px
		z-index 1
	&__title
		text-align center
		width 100%
		margin-bottom 20px
		h2
			font-size 82px
			line-height 76px
			font-weight bold
			letter-spacing -5px
			color $white
			transition $trans
	&__icon
		transition $trans
		path
			transition $trans
	&__content
		margin-top 120px
	&__blocks
		margin-top 60px
		display flex
		align-items flex-start
		justify-content space-between
		div
			border-radius 20px
			padding 40px 0 64px
			width calc(50% - 40px)
			&.white
				background-color $white
				padding 40px 40px 64px
			p
				font-size 16px
				line-height 24px
	

.funcs
	&__main
		margin-top 100px
		margin-bottom -120px
	&__row
		display flex
		align-items center
		justify-content space-between
		padding-bottom 120px
		&.reverse
			flex-direction row-reverse
	&__block
		width calc(50% - 40px)
	&__title
		h2
			font-size 42px
			line-height 46px
			letter-spacing -2px
	&__text,
	&__btn
		margin-top 30px

.features
	&__blocks
		margin-top 60px
		display flex
		align-items stretch
		justify-content space-between
		flex-wrap wrap
		gap 30px 25px
		&.mobile
			.features
				&__block
					min-height 376px
					width 100%
					padding 30px 30px 55px
	&__block
		background-color $white
		width calc(33% - 15px)
		padding 30px
		border-radius 20px
		min-height 376px
		&.black
			background-color $black
			.features
				&__subtitle
					color $white
				&__text
					p
						color $yellow
	&__subtitle
		color $black
		font-size 30px
		line-height 36px
		font-weight 700
		letter-spacing -1.5px
		margin-top 20px
	&__icon
		width 60px
		heigth 60px
	&__text
		margin-top 20px
		p
			font-size 16px
			line-height 24px

.who
	&__blocks
		margin-top 60px
		display flex
		align-items flex-start
		justify-content space-between
		gap 30px
		&.mobile
			.who
				&__block
					width 100%
					min-height 500px
	&__block
		background-color $yellow
		border-radius 20px
		width calc(33% - 15px)
	&__main
		padding 30px
	&__img
		border-top-left-radius 20px
		border-top-right-radius 20px
		width 100%
		height 255px
		background-size cover
		background-position center
		background-repeat no-repeat
	&__subtitle
		font-size 30px
		line-height 36px
		font-weight bold
		margin-bottom 20px
		letter-spacing -1.5px
		color $black
	&__text,
	&__hidden
		font-size 16px
		line-height 24px
		color $black
		transition max-height .5s ease-in
	&__hidden
		overflow hidden
		max-height 0

.subtitle
	margin-top 20px

.content
	margin-top 25px
	h2
		font-size 30px
		line-height 36px
		letter-spacing -1.5px
		padding-bottom 15px
		padding-top 15px
	p
		padding-top 5px
		padding-bottom 15px
		
