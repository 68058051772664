@keyframes typing 
    from  
        width 0
        border-color rgba(255,255,255,.4)
    to  
        width 100%

/* The typewriter cursor effect */
@keyframes blink-caret 
    from, to  
        border-color transparent
    50%  
        border-color rgba(255,255,255,.4)