@media (max-width 890px)
    h1
        font-size 42px
        line-height 35px
    .highlight
        line-height 54px
    .title
        .highlight
            height 60px
    .hero
        &__title
            h1
                letter-spacing -2.5px

@media (min-width 769px)
    .burg,
    .btn--header_mobile
        display none
    .features
        &__blocks
            &.mobile
                display none
    .who
        &__blocks
            &.mobile
                display none
    .footer
        &__text
            &.mobile
                display none

@media (max-width 768px)
    h2
        font-size 42px
        line-height 46px
        letter-spacing -2px
    .header
        &__menu
            display none
    .footer
        padding-bottom 60px
        &__wrapper
            align-items center
            justify-content center
            flex-direction column
        &__text
            &.desktop
                display none
        &__btns
            margin-top 30px
        &__bottom
            margin-top 40px
        &__logo
            margin-bottom 30px
    .btn
        &--header_desktop
            display none
    .section
        padding-top 60px
        padding-bottom 60px
        &--hero
            padding-bottom 0
            padding-top 0
            margin-top 10px
        &--video
            padding-top 310px
        &--funcs
            padding-bottom 20px
        &--features,
        &--who
            .wrapper
                padding-left 0
                padding-right 0
    .hero
        padding 10px 20px 50px
        &-top
            margin-bottom 48px
            &__tab
                display none
        &__subtitle
            margin-top 25px
            font-size 14px
            line-height 20px
        &__form
            margin-top 30px
        &__brands
            margin-top 40px
            flex-wrap wrap
            gap 20px 0
            div
                width calc(50% - 20px)
                margin 0
                img
                    margin auto
        &.no_top
            padding 40px 30px 50px
    .video
        &__blocks
            margin-top 30px
            flex-wrap wrap
            div
                width 100%
                padding 0
                margin-bottom 25px
                &.white
                    padding 30px 30px 35px
        &__title
            margin-bottom 0
            h2
                font-size 42px
                line-height 42px
                letter-spacing -2.5px
        &__icon
            width 75px
            height 75px
            svg
                width 100%
                height 100%
        &__content
            margin-top 60px
    .funcs
        &__row
            flex-wrap wrap
            padding-bottom 40px
        &__main
            margin-bottom 0
        &__block
            width 100%
        &__title
            .title
                text-align center
            h2
                font-size 32px
                line-height 36px
        &__btn
            margin-top 20px
            .btn
                display block
                text-align center
        &__text
            margin-top 20px
        &__main
            margin-top 40px
        &__anim
            padding-bottom 20px
    .features
        &__blocks
            margin-top 30px
            &.desktop
                display none
    .who
        &__blocks
            margin-top 30px
            &.desktop
                display none

@media (max-width 580px)
    .inputs
        &__row
            flex-direction column
            .input
                &:not(:first-child)
                    margin-top 10px
    input
        &::placeholder
            text-align center
    
    .input
        margin-right 0
        width 100%
        input
            width 100%
    .title
        &--with_imgs
            justify-content space-between
        &__imgs
            flex-direction column

    .socials
        &__item
            &:not(:last-child)
                margin-right 50px
        
    .footer
        &__text
            font-size 12px
            line-height 18px

@media (max-width 480px)
    .highlight
        margin 10px 20px
    .hero
        &__form
            max-width 275px
            margin 30px auto 0

    .modal
        padding 32px 20px
