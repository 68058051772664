.footer
    padding-bottom 60px
    &__wrapper
        display flex
        align-items center
        justify-content space-between
    &__logo
        max-width 134px
        width 100%
    &__text
        font-size 12px
        line-height 18px
        font-weight 500
        &.mobile
            max-width 278px
            margin auto
            text-align center
            margin-top 30px
    &__bottom
        margin-top 35px
    &__text,
    .copyright
        color rgba(0,0,0,.5)
        font-size 10px
        line-height 12px
        font-weight 500
    