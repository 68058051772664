.header
    position relative
    padding 30px 0
    z-index 11
    &__wrapper
        display flex
        align-items center
        justify-content space-between
    &__logo
        max-width 134px
        width 100%

.menu
    ul
        display flex
        align-items center
        justify-content center
    a
        display block
        font-size 14px
        line-height 16px
        color $black
        font-weight 500
        letter-spacing -0.44px
        padding 10px 20px
        opacity .7
        transition $trans
        &:hover
            color $yellow

.burg
    width 40px
    height 40px
    border-radius 10px
    border 1px solid rgba(0, 0, 0, .1)
    background-color $white
    display flex
    align-items center
    justify-content space-between
    flex-direction column
    padding 15px 12px
    &__inner
        display flex
        align-items center
        justify-content center
        position relative
        width 100%
        height 100%
    &__line
        position absolute
        width 100%
        height 1px
        background-color $mid_black
        border-radius 2px
        transition $trans
        &:first-child
            top 0
        &:nth-child(2)
            top 0
            left 0
            bottom 0
            margin auto
        &:last-child
            bottom 0
    &.active
        .burg
            &__line
                &:first-child
                    transform rotate(45deg)
                    top unset
                &:nth-child(2)
                    height 0
                &:last-child
                    transform rotate(-45deg)
                    bottom unset

.side
    position absolute
    top 0
    left 0
    width 100%
    min-height 50vh
    background-color $white
    border-bottom-right-radius 20px
    border-bottom-left-radius 20px
    transform translateY(-120%)
    transition $trans
    z-index 10
    &__inner
        padding-top 150px
        padding-bottom 100px
        height 100%
    &.active
        transform translateY(0)
    .menu
        ul
            justify-content center
            flex-direction column
        li
            margin 20px 0
