.fancybox
    &__backdrop
        background rgba(0,0,0,.5)
   .is-close-btn
    display none

.modal
    max-width 640px
    width 100%
    margin auto
    border-radius 20px
    &__wrapper
        display flex
        flex-direction column
        align-items center
    &__icon
        width 60px
        height 60px
        margin-bottom 20px
    &__title
        text-align center
        h2
            font-size 30px
            line-height 36px
            letter-spacing -1.5px
    &__text
        text-align center
        margin-top 20px
        font-size 16px
        line-height 24px
    &__btn
        display flex
        align-items center
        justify-content center
        margin-top 30px