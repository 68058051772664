m-font-face( $font-family = "Arial", $file-path = "", $local-name = "", $weight, $style = normal) {
	@font-face {
		font-family: $font-family;
		src: url($file-path + '.eot');
		src: local($local-name),
			 url($file-path + '.eot?#iefix') format('embedded-opentype'),
			 url($file-path + '.woff2') format('woff2'),
			 url($file-path + '.woff') format('woff'),
			 url($file-path + '.ttf') format('truetype');
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
	}
}

m-font-face("Dmsans", "../fonts/dmsans/DMSans-Regular", "DMSans-Regular", 400);
m-font-face("Dmsans", "../fonts/dmsans/DMSans-Medium", "DMSans-Medium", 500);
m-font-face("Dmsans", "../fonts/dmsans/DMSans-Bold", "DMSans-Bold", 700);